import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import FormContext from './form-context'

function FormValuesListener({ onChange }) {
  const { values, initialValues } = useFormikContext()

  useEffect(() => {
    if (values !== initialValues) {
      onChange(values)
    }
  }, [values, initialValues])

  return null
}

const Form = ({ isLoading, children, onChange = undefined }) => {
  const formContext = {
    isLoading
  }

  return (
    <FormContext.Provider value={formContext}>
      {!!onChange && <FormValuesListener onChange={onChange} />}
      {children}
    </FormContext.Provider>
  )
}

export default Form

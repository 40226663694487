import { Textarea as NBTextArea } from '@chakra-ui/react'
import { InputProps } from '@heytutor/common'
import { useField, useFormikContext } from 'formik'
import React from 'react'
import FieldWrapper from './field-wrapper'

type Props = InputProps & any

const TextArea = ({ label, name, isRequired, helper, placeholder, ...rest }: Props) => {
  const [field] = useField(name)
  const { handleChange, handleBlur, setFieldTouched }: any = useFormikContext()

  return (
    <FieldWrapper label={label} name={name} isRequired={isRequired} helper={helper}>
      <NBTextArea
        {...rest}
        value={field.value}
        onChange={(x) => {
          handleChange(name)(x)
          rest.onChange && rest.onChange(x)
        }}
        onBlur={() => {
          setFieldTouched(name)
          handleBlur(name)
        }}
        placeholder={placeholder}
      />
    </FieldWrapper>
  )
}

export default TextArea

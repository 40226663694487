import { Box } from '@chakra-ui/react'

export default function SearchResultList({ items, renderItem, isLoading }) {
  return (
    <Box
      style={{
        width: '100%',
        backgroundColor: 'white',
        borderWidth: 2,
        borderColor: '#ccc',
        zIndex: 100000,
        cursor: 'pointer'
      }}
    >
      {items.length > 0 ? (
        items.map((item, index) => {
          return <>{renderItem(item, index)}</>
        })
      ) : (
        <Box key={'search-result-no-result'} flexShrink={1} textAlign="center" mx={2} my={2} p={1}>
          {isLoading ? 'Searching...' : 'No results'}
        </Box>
      )}
    </Box>
  )
}

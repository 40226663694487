import { Select as NBSelect } from '@chakra-ui/react'
import { SelectProps } from '@heytutor/common'
import { useField, useFormikContext } from 'formik'
import React from 'react'
import FieldWrapper from './field-wrapper'

type Props = SelectProps & any

const Select = ({ label, name, items, helper, isRequired, style, ...rest }: Props) => {
  const [field] = useField(name)
  const { handleChange }: any = useFormikContext()

  return (
    <FieldWrapper label={label} name={name} isRequired={isRequired} helper={helper} style={style}>
      <NBSelect {...rest} value={field.value} mt={1} onChange={handleChange(name)}>
        {items.map((d, idx) => (
          <option key={idx} label={d.name}>
            {d.value}
          </option>
        ))}
      </NBSelect>
    </FieldWrapper>
  )
}

export default Select

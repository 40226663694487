import { Box, Center, Image } from '@chakra-ui/react'
import ImageIcon from 'components/design-system/bits/image-icon'
import { useFormikContext } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

// const previewSize = 60

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
}
// const thumb = {
//   display: 'inline-flex',
//   borderRadius: 2,
//   border: '1px solid #eaeaea',
//   width: previewSize,
//   height: previewSize,
//   padding: 4,
//   boxSizing: 'border-box',
// }
const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
}
const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
}
const dropzone = {
  padding: '2px',
  borderWidth: '2px',
  borderRadius: '2px',
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

export default function ImageUpload({ name, previewUrl, onChange = null, previewSize = 60 }) {
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    width: previewSize,
    height: previewSize,
    padding: 4,
    boxSizing: 'border-box'
  }
  const [internalPreview, setInternalPreview] = useState<any>()
  const { setFieldValue }: any = useFormikContext()
  const preview = internalPreview || previewUrl
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    const preview = URL.createObjectURL(file)

    setFieldValue(name, file)
    setInternalPreview(preview)
    onChange({ preview, file })
  }, [])
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop
  })

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => {
      if (internalPreview) {
        URL.revokeObjectURL(internalPreview)
      }
    }
  }, [internalPreview])

  return (
    <Box role="section">
      <Box {...getRootProps({ className: 'dropzone' })} style={dropzone}>
        <input {...getInputProps()} />

        {preview ? (
          <Box role="aside" style={thumbsContainer as React.CSSProperties}>
            <Box style={thumb as React.CSSProperties} key={internalPreview || previewUrl}>
              <Box style={thumbInner}>
                <Image
                  src={internalPreview || previewUrl}
                  style={img}
                  // Revoke data uri after image is loaded
                  onLoad={() => {
                    URL.revokeObjectURL(internalPreview || previewUrl)
                  }}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <Center boxSize={`${previewSize}px`}>
            <ImageIcon size={`${previewSize * 0.5}px`} color="grey" m={'6px'} />
          </Center>
        )}
      </Box>
    </Box>
  )
}

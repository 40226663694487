import { Checkbox as NBCheckbox } from '@chakra-ui/react'
import { SwitchProps } from '@heytutor/common'
import { useField, useFormikContext } from 'formik'
import React from 'react'
import FieldWrapper from './field-wrapper'

type Props = SwitchProps & any

const Checkbox = ({ label, name, helper, ...rest }: Props) => {
  const [field] = useField(name)
  const { setFieldValue }: any = useFormikContext()

  return (
    <FieldWrapper label={label} name={name} helper={helper} {...rest}>
      <NBCheckbox
        {...rest}
        isChecked={field.value}
        onChange={() => setFieldValue(name, !field.value)}
        value={field.value}
      />
    </FieldWrapper>
  )
}

export default Checkbox

import { Box, Input, InputGroup, InputLeftElement, Text } from '@chakra-ui/react'
import { SearchInputProps } from '@heytutor/common'
import ClientOnly from 'components/client-only'
import { useState } from 'react'
import SearchIcon from './search-icon'

const SearchInput = ({ items, term, onChange, onSelected, placeholder }: SearchInputProps) => {
  const [hasFocus, setHasFocus] = useState(true)

  const onFocusChange = (focus, e) => {
    setHasFocus(focus)
    onChange(e)
  }

  return (
    <Box style={{ zIndex: 1000 }} onFocus={(e) => onFocusChange(true, e)}>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon />
        </InputLeftElement>
        <Input placeholder={placeholder} value={term} onChange={onChange} />
      </InputGroup>

      {hasFocus && items.length > 0 && (
        <Box
          style={{
            position: 'absolute',
            left: 0,
            top: 70,
            width: '100%',
            backgroundColor: 'white',
            borderWidth: 2,
            borderColor: '#ccc',
            zIndex: 1000
          }}
        >
          {items.map((item, index) => (
            <Text
              key={index}
              width="100%"
              zIndex={1000}
              flexShrink={1}
              textAlign="left"
              mx={2}
              my={2}
              onClick={() => onSelected(item)}
            >
              {item.name}
            </Text>
          ))}
        </Box>
      )}
    </Box>
  )
}

const ClientOnlySearchInput = (props) => (
  <ClientOnly>
    <SearchInput {...props} />
  </ClientOnly>
)

export default ClientOnlySearchInput

import { ChakraProvider, extendTheme, Switch as NBSwitch } from '@chakra-ui/react'
import { SwitchProps } from '@heytutor/common'
import { useField, useFormikContext } from 'formik'
import FieldWrapper from './field-wrapper'

type Props = SwitchProps & any

const Switch = ({ label, name, helper, ...rest }: Props) => {
  const [field] = useField(name)
  const { setFieldValue }: any = useFormikContext()
  const customTheme = extendTheme({
    colors: {
      HeyTutorSwitchGreen: {
        500: '#49C549'
      }
    }
  })

  return (
    <FieldWrapper label={label} name={name} helper={helper}>
      <ChakraProvider theme={customTheme}>
        <NBSwitch
          {...rest}
          colorScheme="HeyTutorSwitchGreen"
          isChecked={field.value}
          onChange={() => {
            setFieldValue(name, !field.value)
          }}
        />
      </ChakraProvider>
    </FieldWrapper>
  )
}

export default Switch

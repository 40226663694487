import { Input as NBInput, InputGroup as NBInputGroup, InputLeftElement as NBInputLeftElement } from '@chakra-ui/react'
import { useField, useFormikContext } from 'formik'
import { debounce } from 'lodash'
import { useCallback, useState } from 'react'
import FieldWrapper from './field-wrapper'
import SearchIcon from './search-icon'
import SearchResultList from './search-result-list'
import SearchResultListItem from './search-result-list-item'

const AutoComplete = ({
  label,
  name,
  search,
  extractText,
  initialValue = null,
  isRequired,
  helper,
  placeholder = 'Start typing to search',
  onChange = null,
  onSelected = null,
  ...rest
}) => {
  const [_field] = useField(name)
  const [items, setItems] = useState<any[]>([])
  const [hasFocus, _setHasFocus] = useState(true)
  const [text, setText] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [showResults, setShowResults] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const { handleChange, handleBlur, setFieldTouched }: any = useFormikContext()

  if (!isInitialized && initialValue) {
    handleChange(name)(initialValue.id)
    setText(initialValue.name)
    setIsInitialized(true)
  }

  const onSelectedAutoComplete = async (item) => {
    handleChange(name)(item.id)
    setText(item.name)
    setItems([])
    setIsSearching(false)
    setShowResults(false)
    onChange && onChange(item)
  }

  const onSearch = async (event) => {
    const text = event.target.value

    setText(text)
    setItems([])

    await debouncedSearch(text)
  }

  const debouncedSearch = useCallback(
    debounce(async (text) => {
      if (text.length > 0) {
        setShowResults(true)
        setIsSearching(true)

        const result = await search(text)

        setItems(result)
        setIsSearching(false)
      }
    }, 500),
    [search]
  )

  return (
    <>
      <FieldWrapper label={label} name={name} isRequired={isRequired} helper={helper}>
        <NBInputGroup>
          <NBInputLeftElement pointerEvents="none">
            <SearchIcon />
          </NBInputLeftElement>
          <NBInput
            {...rest}
            value={text}
            placeholder={placeholder}
            onChange={onSearch}
            onBlur={() => {
              setFieldTouched(name)
              handleBlur(name)

              setTimeout(() => setShowResults(false), 200)
            }}
          />
        </NBInputGroup>
      </FieldWrapper>
      {hasFocus && text && text.length > 0 && showResults && (
        <SearchResultList
          items={items}
          isLoading={isSearching}
          renderItem={(item, index) => (
            <SearchResultListItem
              key={`search-result-item-${index}`}
              index={index}
              text={extractText(item)}
              onSelected={() => (onSelected ? onSelected(item) : onSelectedAutoComplete(item))}
            />
          )}
        />
      )}
    </>
  )
}

export default AutoComplete

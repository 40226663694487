import { useListLevelsQuery } from 'graphql/generated'
import { get } from 'lodash'
import Select from './select'

export default function LevelSelect({ label, name, ...rest }) {
  const [levelsResult] = useListLevelsQuery()
  const levels = get(levelsResult, 'data.levels', []).map((x) => ({
    value: x.id,
    name: x.name
  }))

  return <Select label={label} name={name} items={levels} {...rest} />
}

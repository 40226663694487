import { Badge, HStack, Text } from '@chakra-ui/react'
import React from 'react'
import DeleteIcon from './bits/delete-icon'

const MetatagsList = ({ metaTags, onDelete, isSubmitting }) => {
  return metaTags.map((item, index) => (
    <Badge
      key={index}
      onClick={() => onDelete(item)}
      rounded="full"
      pointerEvents={isSubmitting ? 'none' : 'auto'}
      cursor={isSubmitting ? 'not-allowed' : 'pointer'}
      colorScheme="green"
      p={2}
      mb={2}
      mr={2}
      alignSelf="flex-start"
    >
      <HStack>
        <Text>{item.name}</Text>
        {onDelete && <DeleteIcon size={14} />}
      </HStack>
    </Badge>
  ))
}

export default MetatagsList

import React from 'react'

const ClientOnly = ({ children, ...delegated }) => {
  const [hasMounted, setHasMounted] = React.useState(false)

  React.useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true)
    }
  }, [])

  if (!hasMounted) {
    return null
  }

  return <React.Fragment {...delegated}>{children}</React.Fragment>
}

export default ClientOnly

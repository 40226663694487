import { Box } from '@chakra-ui/react'
import { SelectItem, TagsInputProps } from '@heytutor/common'
import { useField, useFormikContext } from 'formik'
import { useListMetaTagsQuery } from 'graphql/generated'
import { debounce, get } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import MetatagsList from '../metatags-list'
import FieldWrapper from './field-wrapper'
import SearchInput from './search-input'

const TagsInput = ({ label, name, items, onSearch, isRequired, helper, placeholder, ...rest }: TagsInputProps) => {
  const [field] = useField(name)
  const { setFieldValue, isSubmitting }: any = useFormikContext()
  const [term, setTerm] = useState('')
  const [selectedTags, setSelectedTags] = useState((field.value || []) as SelectItem[])
  const searchResult = items.filter((x) => !selectedTags.map((selected) => selected.value).includes(x.value))
  const zIndex = 1000

  useEffect(() => {
    setSelectedTags((field.value || []) as SelectItem[])

    return () => {}
  }, [field.value])

  const onChange = (e) => {
    if (e.target != undefined) {
      const text = e.target.value

      setTerm(text)
      onSearch(text)
    }
  }

  const onSelected = (item: SelectItem) => {
    setTerm('')
    onSearch('')

    setSelectedTags((prev) => {
      if (!prev.map((x) => x.value).includes(item.value)) {
        const newList = [...prev, item]

        setFieldValue(name, newList)

        return newList
      }

      prev
    })
  }

  const onDelete = (item: SelectItem) => {
    setTerm('')
    onSearch('')

    setSelectedTags((prev) => {
      const newList = prev.filter((x) => x.value != item.value)

      setFieldValue(name, newList)

      return newList
    })
  }

  return (
    <Box {...rest}>
      <FieldWrapper label={label} name={name} isRequired={isRequired} helper={helper} style={{ zIndex }}>
        <SearchInput
          items={searchResult.filter((x) => !selectedTags.map((selected) => selected.value).includes(x.value))}
          term={term}
          onChange={onChange}
          onSelected={onSelected}
          placeholder={placeholder}
          style={{ zIndex }}
        />
      </FieldWrapper>

      <Box my={2} display="block">
        <MetatagsList metaTags={selectedTags} onDelete={onDelete} isSubmitting={isSubmitting} />
      </Box>
    </Box>
  )
}

function TagsInputContainer({ ...rest }) {
  const [tagName, setTagName] = useState(undefined)
  const [metaTagsResult, _] = useListMetaTagsQuery({
    variables: { cc_standards: tagName, first: 10, page: 1 }
  })
  const items = get(metaTagsResult, 'data.metaTags.data', []).map((x) => ({
    value: x.id,
    name: x.name
  }))
  const debouncedSearch = useCallback(
    debounce(async (text) => {
      if (text.length > 0) {
        setTagName(text)
      } else {
        setTagName(undefined)
      }
    }, 100),
    []
  )

  return (
    <TagsInput
      label="Tags"
      name="metaTags"
      items={tagName && !metaTagsResult.fetching ? items : []}
      onSearch={debouncedSearch}
      {...rest}
    />
  )
}

export default TagsInputContainer

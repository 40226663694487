import { InputProps } from '@heytutor/common'
import { Editor } from '@tinymce/tinymce-react'
import { useField, useFormikContext } from 'formik'
import { get } from 'lodash'
import { nanoid } from 'nanoid'
import { useRef, useState } from 'react'
import { TINYMCE_API_KEY } from 'utils/config'
import FieldWrapper from './field-wrapper'

export type ImagesUploadHandlerResult = {
  location: string
}

export type ImagesUploadHandler = (blobInfo: any, success: any, failure: any) => Promise<ImagesUploadHandlerResult>

type Props = InputProps & { imageUploadHandler?: ImagesUploadHandler } & any

const SimpleRichEditor = ({ label, name, tools, imageUploadHandler, isRequired, helper, ...rest }: Props) => {
  const editorRef = useRef(null)
  const [field] = useField(name)
  const { handleChange }: any = useFormikContext()
  const [text, setText] = useState<string>(undefined)
  const [initialized, _setInitialized] = useState<boolean>(false)
  const imageUploadEditorConfig = imageUploadHandler
    ? {
        file_picker_types: 'file image media',
        images_upload_handler: imageUploadHandler
      }
    : {
        paste_data_images: false
      }
  const toolbar =
    tools == 'none'
      ? ''
      : 'undo redo | blocks | ' +
        'bold italic underline forecolor | alignleft aligncenter ' +
        'alignright alignjustify | bullist numlist outdent indent | ' +
        'superscript subscript | removeformat | image | help'
  const plugins =
    tools == 'none'
      ? []
      : [
          'paste',
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'media',
          'table',
          'code',
          'help',
          'wordcount'
        ]

  if (!initialized && !text && field.value) {
    setText(field.value || '')
  }

  return (
    <FieldWrapper label={label} name={name} isRequired={isRequired} helper={helper}>
      <Editor
        id={nanoid()}
        apiKey={TINYMCE_API_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={text}
        init={{
          height: get(rest, 'style.height', 300),
          menubar: false,
          paste_as_text: false,
          plugins: plugins,
          toolbar: toolbar,
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          ...imageUploadEditorConfig
        }}
        onEditorChange={(value) => {
          setText(value)
          handleChange(name)(value)
        }}
        // onBlur={() => {
        //   setFieldTouched(name)
        //   handleBlur(name)
        // }}
        {...rest}
      />
    </FieldWrapper>
  )
}

export default SimpleRichEditor

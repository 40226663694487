import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Skeleton } from '@chakra-ui/react'
import { useField, useFormikContext } from 'formik'
import { useContext } from 'react'
import FormContext from './form-context'

const FieldWrapper = ({ children, label = null, name, isRequired = false, helper = null, style = null, ...rest }) => {
  const [, { error }] = useField(name)
  const { isSubmitting } = useFormikContext()
  const formContext = useContext(FormContext)
  const wrapperStyles = rest.noExpand ? { flex: 0 } : {}
  const isLoading = false
  const shouldShowHelper = !error && helper

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={!!error}
      isDisabled={isSubmitting}
      style={{ ...style, ...wrapperStyles }}
    >
      {label && <FormLabel>{label}</FormLabel>}

      <Skeleton isLoaded={!formContext.isLoading}>{children}</Skeleton>

      {shouldShowHelper && <FormHelperText mb={2}>{helper}</FormHelperText>}

      {error && !isLoading && <FormErrorMessage mb={2}>{error}</FormErrorMessage>}
    </FormControl>
  )
}

export default FieldWrapper

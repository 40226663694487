import { Icon } from '@chakra-ui/react'
import React from 'react'
import { FiImage } from 'react-icons/fi'

type IconProps = {
  size: number
}

type Props = IconProps & any

const ImageIcon = ({ size, ...props }: Props) => {
  return <Icon color="black" style={{ fontSize: size }} as={FiImage} {...props} />
}

export default ImageIcon

import { Input as NBInput } from '@chakra-ui/react'
import { InputProps } from '@heytutor/common'
import { useField, useFormikContext } from 'formik'
import React from 'react'
import FieldWrapper from './field-wrapper'

type Props = InputProps & any

const Input = ({ label, name, isRequired, helper, ...rest }: Props) => {
  const [field] = useField(name)
  const { handleChange, handleBlur, setFieldTouched }: any = useFormikContext()

  return (
    <FieldWrapper label={label} name={name} isRequired={isRequired} helper={helper}>
      <NBInput
        {...rest}
        value={field.value}
        onChange={handleChange(name)}
        onBlur={() => {
          setFieldTouched(name)
          handleBlur(name)
        }}
      />
    </FieldWrapper>
  )
}

export default Input
